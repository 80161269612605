import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  ErrorPolicy,
  InMemoryCache,
} from "@apollo/client"
import { setContext } from "@apollo/client/link/context"
import { relayStylePagination } from "@apollo/client/utilities"
import React, { useMemo } from "react"
import { appEnv } from "../util/environment"
import { mergedRelayStylePagination } from "../util/mergedRelayStylePagination"
import { useAuthContext } from "./AuthProvider"

export const AppApolloProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const { token } = useAuthContext()

  const client = useMemo(() => {
    const httpLink = createHttpLink({
      uri: appEnv().apiEndpoint,
    })

    const authLink = setContext((_, { headers }) => {
      return {
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : "",
        },
      }
    })

    const errorPolicy: ErrorPolicy = "none"
    const baseOptions = { errorPolicy }

    const cache = new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            fiveTwoNineAccounts: relayStylePagination(["userId"]),
            deductions: relayStylePagination(["userId"]),

            // TODO: switch from mergedRelayStylePagination to relayStylePagination and use edges instead of nodes
            contacts: mergedRelayStylePagination([
              "search",
              "excludeExistingUsers",
            ]),
            contributions: mergedRelayStylePagination(["role"]),
            friends: mergedRelayStylePagination([]),
            users: mergedRelayStylePagination(["search"]),
          },
        },
      },
    })

    return new ApolloClient({
      uri: "/graphql",
      link: ApolloLink.from([authLink.concat(httpLink)]),
      cache,
      defaultOptions: {
        watchQuery: { ...baseOptions, fetchPolicy: "cache-and-network" },
        query: baseOptions,
        mutate: baseOptions,
      },
    })
  }, [token])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}
