import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
import {
  getSessionToken,
  removeSessionToken,
  setSessionToken,
} from "./sessionPersistence"

interface AuthContextType {
  token: string | null
  setToken: (token: string) => void
  clearToken: () => void
}

const AuthContext = createContext<AuthContextType | null>(null)

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [token, _setToken] = useState<string | null>(null)

  useEffect(() => {
    // get the token from the session storage
    const loadToken = async () => {
      const t = await getSessionToken()
      _setToken(t)
    }

    void loadToken()
  }, [])

  const setToken = useCallback(
    (_token: string) => {
      _setToken(_token)
      void setSessionToken(_token)
    },
    [_setToken],
  )

  // Use useLogout hook for logging out
  const clearToken = useCallback(() => {
    _setToken(null)
    void removeSessionToken()
  }, [_setToken])

  const value = useMemo(
    () => ({
      token,
      setToken,
      clearToken,
    }),
    [setToken, clearToken, token],
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuthContext = () => {
  const context = useContext(AuthContext)
  if (!context) throw new Error("Context has not been Provided!")
  return context
}
