import { useMutation } from "@apollo/client"

type UseMutationType = typeof useMutation

/** This function captures network errors so they are not thrown.
 *  This means that instead of needing a try/catch around every mutation call, we can use
 *  response.networkError.
 */
export const useSafeMutation: UseMutationType = (...args) => {
  const [arg1, options, ...otherArgs] = args
  return useMutation(
    arg1,
    {
      onError: () => null,
      ...options,
    },
    ...otherArgs,
  )
}
