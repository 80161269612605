import { ApolloError, ApolloQueryResult, useQuery } from "@apollo/client"
import React, { createContext, useContext, useEffect, useMemo } from "react"
import {
  ViewerFragment,
  ViewerQuery,
  ViewerQueryVariables,
} from "../generated/graphql"
import { GET_VIEWER } from "../graphql/queries"
import { useLogout } from "../util/useLogout"

export type Viewer = ViewerFragment

interface ViewerContextBaseType {
  loading: boolean
  reloadViewer: (
    variables?: Partial<{ [key: string]: never }> | undefined,
  ) => Promise<ApolloQueryResult<ViewerQuery>>
  error?: ApolloError
}
interface ViewerContextType extends ViewerContextBaseType {
  viewer: Viewer | undefined | null
}
interface StrictViewerContextType extends ViewerContextBaseType {
  viewer: Viewer
}

const ViewerContext = createContext<ViewerContextType | null>(null)

export const ViewerProvider = ({ children }: { children: React.ReactNode }) => {
  const { loading, error, data, refetch } = useQuery<
    ViewerQuery,
    ViewerQueryVariables
  >(GET_VIEWER)
  const logout = useLogout()

  useEffect(() => {
    if (error) {
      console.warn(error)
      if (error.message === "Session Not Found") {
        void logout()
      }
    }
  }, [logout, error])

  const value = useMemo(
    () => ({
      viewer: data?.viewer,
      reloadViewer: () => refetch(),
      loading,
      error,
    }),
    [data?.viewer, loading, error, refetch],
  )

  if (loading && !data?.viewer) {
    return null
  }

  return (
    <ViewerContext.Provider value={value}>{children}</ViewerContext.Provider>
  )
}

export const useViewerMaybe = () => {
  const contextValue = useContext(ViewerContext)
  if (contextValue === null) {
    throw Error("Context has not been Provided!")
  }
  return contextValue
}

export const useViewer = (): StrictViewerContextType => {
  const result = useViewerMaybe()
  if (!result.viewer) {
    throw new Error("invariant: no viewer, did you need useViewerMaybe")
  }
  return result as StrictViewerContextType
}
