import { useApolloClient } from "@apollo/client"
import { useAuthContext } from "../authentication/AuthProvider"
import { LOGOUT } from "../graphql/queries"
import { useSafeMutation } from "./useSafeMutation"

export const useLogout = () => {
  const { clearToken } = useAuthContext()
  const [execLogout] = useSafeMutation(LOGOUT)
  const client = useApolloClient()

  const logout = async () => {
    execLogout({ variables: { input: {} } })
      .then(() => {
        clearToken()
        client.clearStore()
        window.location.href = "/dashboard"
      })
      .catch((error) => console.warn(error))
  }

  return logout
}
