import { invariant } from "@apollo/client/utilities/globals"
import Skyflow from "skyflow-js"
import { Env as SkyflowEnv } from "skyflow-js/types/utils/common"

declare global {
  interface Window {
    railsEnv: string
    reactAppEnv: EnvironmentName
  }
}

type EnvironmentName = "debug" | "internal" | "release"

interface EnvironmentValues {
  name: EnvironmentName
  apiEndpoint: string
  isSentryEnabled: boolean
  amplitudeId: string | null
  isAnalyticsDebugging?: boolean
  skyflowEnv: SkyflowEnv
}

export const webLinks: { [key: string]: string } = {
  termsOfUse: "https://www.gohadley.com/legal/terms",
  privacyPolicy: "https://www.gohadley.com/legal/app_privacy",
  legal: "https://www.gohadley.com/legal",
  faq: "https://www.gohadley.com/faq",
  questionnaireExplained: "https://www.gohadley.com/find_a_plan/explained",
}

const environmentMap: Record<EnvironmentName, EnvironmentValues> = {
  debug: {
    name: "debug",
    apiEndpoint: "/graphql",
    // apiEndpoint: "http://10.0.2.2:3000/graphql", // Android host loopback
    //apiEndpoint: "https://staging-api.gohadley.com/graphql",
    // apiEndpoint: `http://192.168.0.36:5005/graphql`,
    //apiEndpoint: "http://1d05-104-246-131-22.ngrok.io/graphql",
    isSentryEnabled: false,
    amplitudeId: null,
    // amplitudeId: `4749a754a1fc546a01c615af23c39b77`,
    isAnalyticsDebugging: true,
    skyflowEnv: Skyflow.Env.DEV,
  },
  internal: {
    name: "internal",
    apiEndpoint: "https://staging-api.gohadley.com/graphql",
    isSentryEnabled: true,
    amplitudeId: "4749a754a1fc546a01c615af23c39b77",
    skyflowEnv: Skyflow.Env.DEV,
  },
  release: {
    name: "release",
    apiEndpoint: "https://api.gohadley.com/graphql",
    isSentryEnabled: true,
    amplitudeId: "89502f8c58ae7ae8f26fb88e2419e214",
    skyflowEnv: Skyflow.Env.PROD,
  },
}

function getCurrentEnv(): EnvironmentName {
  return window.reactAppEnv
}

export const appEnv = () => {
  let value = environmentMap[getCurrentEnv()]
  invariant(value, `Invalid environment: ${getCurrentEnv()}`)
  return value
}
