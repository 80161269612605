import { useQuery } from "@apollo/client"
import { FiveTwoNineAccountQuery } from "../generated/graphql"
import { GET_529_ACCOUNT } from "../graphql/queries"

export const useFiveTwoNineQuery = (idOrSlug = "") =>
  useQuery<FiveTwoNineAccountQuery>(GET_529_ACCOUNT, {
    variables: {
      idOrSlug,
    },
    skip: !idOrSlug,
    onError: (e) => {
      console.error(e)
    },
  })
