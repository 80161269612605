import * as amplitude from "@amplitude/analytics-browser"
import { appEnv } from "../util/environment"

function isAnalytics() {
  return appEnv().amplitudeId != null
}

function log(...args: Parameters<typeof console.info>) {
  if (appEnv().isAnalyticsDebugging) {
    console.info(...args)
  }
}

export function initializeAnalytics() {
  const { amplitudeId } = appEnv()

  if (amplitudeId == null) {
    log("[analytics] skipping initializing", amplitudeId)
    return
  }
  log("[analytics] initializing", amplitudeId)

  void amplitude.init(amplitudeId)
}

export function logEvent(
  eventInput: string | amplitude.Types.BaseEvent,
  eventProperties?: Record<string, any>,
  eventOptions?: amplitude.Types.EventOptions,
) {
  log("[analytics] logEvent", { eventInput, eventProperties, eventOptions })
  if (isAnalytics())
    void amplitude.logEvent(eventInput, eventProperties, eventOptions)
}

export function setUserId(userId?: string) {
  log("[analytics] setUserId", { userId })
  if (isAnalytics()) void amplitude.setUserId(userId)
}

export function setUserProperties(
  identify: amplitude.Types.Identify,
  eventOptions?: amplitude.Types.EventOptions,
) {
  log("[analytics] setUserProperties", { identify, eventOptions })
  if (isAnalytics()) void amplitude.identify(identify, eventOptions)
}
