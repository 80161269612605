import { useToast } from "@/components/ui/use-toast"
import { useQuery } from "@apollo/client"
import { UseFormSetError } from "react-hook-form"
import * as yup from "yup"
import { GetProgramByIdQuery } from "../generated/graphql"
import { GET_PROGRAM_BY_ID } from "../graphql/queries"
import { useFiveTwoNineQuery } from "./useFiveTwoNineQuery"

// Handle fetching 529 account program id and account program so we can get the minimum contribution
export const useMinimumPayment = (idOrSlug?: string) => {
  const { data: fetchedFiveTwoNineAccount, loading } =
    useFiveTwoNineQuery(idOrSlug)

  const programId = fetchedFiveTwoNineAccount?.fiveTwoNineAccount.programId

  const { data: fetchedProgram } = useQuery<GetProgramByIdQuery>(
    GET_PROGRAM_BY_ID,
    {
      variables: {
        id: programId,
      },
      skip: !programId,
    },
  )

  const minimum = fetchedProgram?.program
    ? fetchedProgram?.program.minimumSubsequentContributionAmountCents / 100
    : undefined

  return { minimum, loading }
}

export const dollarAmountValidation = (minimum: number = 1) => ({
  amount: yup
    .number()
    .typeError("Amount should be a number")
    .required("Amount is required")
    .min(minimum, `Amount must be greater than $${minimum}`)
    .max(
      10000,
      "Hadley doesn’t support contributions over $10,000 at this time. Please adjust your contribution.",
    ),
})

export const displayValidationErrors = ({
  recordType,
  errors,
  setError,
  validationErrorTitle,
  toast,
}: {
  recordType: string
  errors?: { path: string; fullMessages: string[] }[]
  setError: UseFormSetError<any>
  validationErrorTitle: string
  toast: ReturnType<typeof useToast>["toast"]
}) => {
  if (
    !errors ||
    errors.length === 0 ||
    errors.filter((error) => !!error.path).length === 0
  ) {
    toast({
      title: "An error has occurred",
      variant: "destructive",
    })
    return
  }

  toast({
    title: validationErrorTitle,
    variant: "destructive",
  })

  errors?.forEach((error) => {
    const key = error.path.replace(`${recordType}.`, "")
    setError(key, {
      type: "server",
      message: error.fullMessages.join("\n"),
    })
  })
}
