import { Reference } from "@apollo/client/cache"
import {
  KeyArgsFunction,
  KeySpecifier,
} from "@apollo/client/cache/inmemory/policies"
import { relayStylePagination } from "@apollo/client/utilities"
import {
  TRelayEdge,
  TRelayPageInfo,
} from "@apollo/client/utilities/policies/pagination"

// Extends the relayStylePagination to merge new pages with existing ones
export const mergedRelayStylePagination = (
  keyArgs: false | KeySpecifier | KeyArgsFunction | undefined,
) => {
  const { read, merge } = relayStylePagination()
  return {
    keyArgs: keyArgs,
    read,
    merge(
      existing: Readonly<
        Readonly<{
          edges: TRelayEdge<Reference>[]
          nodes: any[]
          pageInfo: TRelayPageInfo
        }>
      >,
      incoming: Readonly<
        Readonly<{
          edges: TRelayEdge<Reference>[]
          nodes: any[]
          pageInfo: TRelayPageInfo
        }>
      >,
      opts: any,
    ) {
      if (typeof merge !== "function") return {}
      const defaultMerge = merge(existing, incoming, opts)
      const existingNodes = existing?.nodes || []
      const incomingNodes = incoming?.nodes || []
      return {
        ...defaultMerge,
        nodes: [...existingNodes, ...incomingNodes],
      }
    },
  }
}
